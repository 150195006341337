/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScrimmageRecordVariant
 */
export interface ScrimmageRecordVariant {
    /**
     * 
     * @type {number}
     * @memberof ScrimmageRecordVariant
     */
    wins: number;
    /**
     * 
     * @type {number}
     * @memberof ScrimmageRecordVariant
     */
    losses: number;
    /**
     * 
     * @type {number}
     * @memberof ScrimmageRecordVariant
     */
    ties: number;
}

/**
 * Check if a given object implements the ScrimmageRecordVariant interface.
 */
export function instanceOfScrimmageRecordVariant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "wins" in value;
    isInstance = isInstance && "losses" in value;
    isInstance = isInstance && "ties" in value;

    return isInstance;
}

export function ScrimmageRecordVariantFromJSON(json: any): ScrimmageRecordVariant {
    return ScrimmageRecordVariantFromJSONTyped(json, false);
}

export function ScrimmageRecordVariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScrimmageRecordVariant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wins': json['wins'],
        'losses': json['losses'],
        'ties': json['ties'],
    };
}

export function ScrimmageRecordVariantToJSON(value?: ScrimmageRecordVariant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wins': value.wins,
        'losses': value.losses,
        'ties': value.ties,
    };
}

