/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HistoricalRating,
  Match,
  MatchReportRequest,
  PaginatedMatchList,
  PaginatedScrimmageRequestList,
  PaginatedSubmissionList,
  ScrimmageRecord,
  ScrimmageRequest,
  ScrimmageRequestRequest,
  Submission,
  SubmissionDownload,
  SubmissionReportRequest,
  TournamentSubmission,
} from '../models';
import {
    HistoricalRatingFromJSON,
    HistoricalRatingToJSON,
    MatchFromJSON,
    MatchToJSON,
    MatchReportRequestFromJSON,
    MatchReportRequestToJSON,
    PaginatedMatchListFromJSON,
    PaginatedMatchListToJSON,
    PaginatedScrimmageRequestListFromJSON,
    PaginatedScrimmageRequestListToJSON,
    PaginatedSubmissionListFromJSON,
    PaginatedSubmissionListToJSON,
    ScrimmageRecordFromJSON,
    ScrimmageRecordToJSON,
    ScrimmageRequestFromJSON,
    ScrimmageRequestToJSON,
    ScrimmageRequestRequestFromJSON,
    ScrimmageRequestRequestToJSON,
    SubmissionFromJSON,
    SubmissionToJSON,
    SubmissionDownloadFromJSON,
    SubmissionDownloadToJSON,
    SubmissionReportRequestFromJSON,
    SubmissionReportRequestToJSON,
    TournamentSubmissionFromJSON,
    TournamentSubmissionToJSON,
} from '../models';

export interface CompeteMatchHistoricalRatingRetrieveRequest {
    episodeId: string;
    teamId?: number;
}

export interface CompeteMatchHistoricalRatingTopNListRequest {
    episodeId: string;
    n?: number;
}

export interface CompeteMatchListRequest {
    episodeId: string;
    page?: number;
}

export interface CompeteMatchPublishPublicBracketCreateRequest {
    episodeId: string;
    id: string;
}

export interface CompeteMatchRatingUpdateCreateRequest {
    episodeId: string;
    id: string;
}

export interface CompeteMatchReportCreateRequest {
    episodeId: string;
    id: string;
    matchReportRequest: MatchReportRequest;
}

export interface CompeteMatchRetrieveRequest {
    episodeId: string;
    id: string;
}

export interface CompeteMatchScrimmageListRequest {
    episodeId: string;
    page?: number;
    teamId?: number;
}

export interface CompeteMatchScrimmagingRecordRetrieveRequest {
    episodeId: string;
    teamId?: number;
}

export interface CompeteMatchTournamentListRequest {
    episodeId: string;
    externalIdPrivate?: string;
    page?: number;
    roundId?: number;
    teamId?: number;
    tournamentId?: string;
}

export interface CompeteRequestAcceptCreateRequest {
    episodeId: string;
    id: string;
}

export interface CompeteRequestCreateRequest {
    episodeId: string;
    scrimmageRequestRequest: ScrimmageRequestRequest;
}

export interface CompeteRequestDestroyRequest {
    episodeId: string;
    id: string;
}

export interface CompeteRequestInboxListRequest {
    episodeId: string;
    page?: number;
}

export interface CompeteRequestOutboxListRequest {
    episodeId: string;
    page?: number;
}

export interface CompeteRequestRejectCreateRequest {
    episodeId: string;
    id: string;
}

export interface CompeteSubmissionCreateRequest {
    episodeId: string;
    _package?: string;
    description?: string;
    sourceCode?: Blob;
}

export interface CompeteSubmissionDownloadRetrieveRequest {
    episodeId: string;
    id: string;
}

export interface CompeteSubmissionListRequest {
    episodeId: string;
    page?: number;
}

export interface CompeteSubmissionReportCreateRequest {
    episodeId: string;
    id: string;
    submissionReportRequest: SubmissionReportRequest;
}

export interface CompeteSubmissionRetrieveRequest {
    episodeId: string;
    id: string;
}

export interface CompeteSubmissionTournamentListRequest {
    episodeId: string;
}

/**
 * 
 */
export class CompeteApi extends runtime.BaseAPI {

    /**
     * Provides a list of historical ratings for a team in a given episode. Supports filtering by team ID or defaults to the current user\'s team if no team ID is provided.  Parameters:     - request (Request) - The HTTP request object.     - pk (int, optional) - The primary key of the object. Defaults to None.     - episode_id (int) - The ID of the episode to filter the ratings by.  Query Parameters:     - team_id (int, optional) - The team ID for which to retrieve     historical ratings. If not provided, defaults to the team of the     requesting user.  Returns:     Response: A JSON response containing:         - 200 OK: Returns a serialized representation of the team\'s         historical ratings if found.         - 204 No Content: If no ranked matches are found for the specified team.         - 400 Bad Request: If the specified team could not be found.  Raises:     - 400 Bad Request: If neither a valid team ID is provided nor can a team be     determined from the current user.  Permissions:     Requires `IsEpisodeMutable` permission class.  Notes:     - The function does not paginate results.     - The function returns an empty list if no valid team is found.     - Historical ratings are ordered by match creation date.
     */
    async competeMatchHistoricalRatingRetrieveRaw(requestParameters: CompeteMatchHistoricalRatingRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoricalRating>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchHistoricalRatingRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/historical_rating/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoricalRatingFromJSON(jsonValue));
    }

    /**
     * Provides a list of historical ratings for a team in a given episode. Supports filtering by team ID or defaults to the current user\'s team if no team ID is provided.  Parameters:     - request (Request) - The HTTP request object.     - pk (int, optional) - The primary key of the object. Defaults to None.     - episode_id (int) - The ID of the episode to filter the ratings by.  Query Parameters:     - team_id (int, optional) - The team ID for which to retrieve     historical ratings. If not provided, defaults to the team of the     requesting user.  Returns:     Response: A JSON response containing:         - 200 OK: Returns a serialized representation of the team\'s         historical ratings if found.         - 204 No Content: If no ranked matches are found for the specified team.         - 400 Bad Request: If the specified team could not be found.  Raises:     - 400 Bad Request: If neither a valid team ID is provided nor can a team be     determined from the current user.  Permissions:     Requires `IsEpisodeMutable` permission class.  Notes:     - The function does not paginate results.     - The function returns an empty list if no valid team is found.     - Historical ratings are ordered by match creation date.
     */
    async competeMatchHistoricalRatingRetrieve(requestParameters: CompeteMatchHistoricalRatingRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoricalRating> {
        const response = await this.competeMatchHistoricalRatingRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the historical top N rankings, N should be <= 10 and defaults to 10
     */
    async competeMatchHistoricalRatingTopNListRaw(requestParameters: CompeteMatchHistoricalRatingTopNListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HistoricalRating>>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchHistoricalRatingTopNList.');
        }

        const queryParameters: any = {};

        if (requestParameters.n !== undefined) {
            queryParameters['N'] = requestParameters.n;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/historical_rating_topN/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HistoricalRatingFromJSON));
    }

    /**
     * List the historical top N rankings, N should be <= 10 and defaults to 10
     */
    async competeMatchHistoricalRatingTopNList(requestParameters: CompeteMatchHistoricalRatingTopNListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HistoricalRating>> {
        const response = await this.competeMatchHistoricalRatingTopNListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset for viewing and retrieving Matches.
     */
    async competeMatchListRaw(requestParameters: CompeteMatchListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMatchList>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMatchListFromJSON(jsonValue));
    }

    /**
     * A viewset for viewing and retrieving Matches.
     */
    async competeMatchList(requestParameters: CompeteMatchListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMatchList> {
        const response = await this.competeMatchListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish the result of a tournament match to the public bracket.
     */
    async competeMatchPublishPublicBracketCreateRaw(requestParameters: CompeteMatchPublishPublicBracketCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchPublishPublicBracketCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeMatchPublishPublicBracketCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/{id}/publish_public_bracket/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish the result of a tournament match to the public bracket.
     */
    async competeMatchPublishPublicBracketCreate(requestParameters: CompeteMatchPublishPublicBracketCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.competeMatchPublishPublicBracketCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Try to finalize the rating of this match.
     */
    async competeMatchRatingUpdateCreateRaw(requestParameters: CompeteMatchRatingUpdateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchRatingUpdateCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeMatchRatingUpdateCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/{id}/rating_update/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Try to finalize the rating of this match.
     */
    async competeMatchRatingUpdateCreate(requestParameters: CompeteMatchRatingUpdateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.competeMatchRatingUpdateCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Report the outcome of this match on Saturn.
     */
    async competeMatchReportCreateRaw(requestParameters: CompeteMatchReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchReportCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeMatchReportCreate.');
        }

        if (requestParameters.matchReportRequest === null || requestParameters.matchReportRequest === undefined) {
            throw new runtime.RequiredError('matchReportRequest','Required parameter requestParameters.matchReportRequest was null or undefined when calling competeMatchReportCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/{id}/report/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MatchReportRequestToJSON(requestParameters.matchReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Report the outcome of this match on Saturn.
     */
    async competeMatchReportCreate(requestParameters: CompeteMatchReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.competeMatchReportCreateRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset for viewing and retrieving Matches.
     */
    async competeMatchRetrieveRaw(requestParameters: CompeteMatchRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Match>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeMatchRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/{id}/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatchFromJSON(jsonValue));
    }

    /**
     * A viewset for viewing and retrieving Matches.
     */
    async competeMatchRetrieve(requestParameters: CompeteMatchRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Match> {
        const response = await this.competeMatchRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all scrimmages that a particular team participated in.
     */
    async competeMatchScrimmageListRaw(requestParameters: CompeteMatchScrimmageListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMatchList>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchScrimmageList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/scrimmage/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMatchListFromJSON(jsonValue));
    }

    /**
     * List all scrimmages that a particular team participated in.
     */
    async competeMatchScrimmageList(requestParameters: CompeteMatchScrimmageListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMatchList> {
        const response = await this.competeMatchScrimmageListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the scrimmaging win-loss-tie record for a team.  Returns a JSON object containing the team\'s record in ranked, unranked, and overall matches. The record is broken down into wins, losses, and ties for each category.  Query Parameters: - team_id (optional): ID of the team to retrieve the record for.                       If not provided, uses the authenticated user\'s team.  Returns: - 200 OK: Successfully retrieved the team\'s scrimmaging record. - 400 Bad Request: If no team_id is provided and the user is not associated                       with a team, or if the provided team_id is invalid.
     */
    async competeMatchScrimmagingRecordRetrieveRaw(requestParameters: CompeteMatchScrimmagingRecordRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScrimmageRecord>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchScrimmagingRecordRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/scrimmaging_record/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScrimmageRecordFromJSON(jsonValue));
    }

    /**
     * Retrieve the scrimmaging win-loss-tie record for a team.  Returns a JSON object containing the team\'s record in ranked, unranked, and overall matches. The record is broken down into wins, losses, and ties for each category.  Query Parameters: - team_id (optional): ID of the team to retrieve the record for.                       If not provided, uses the authenticated user\'s team.  Returns: - 200 OK: Successfully retrieved the team\'s scrimmaging record. - 400 Bad Request: If no team_id is provided and the user is not associated                       with a team, or if the provided team_id is invalid.
     */
    async competeMatchScrimmagingRecordRetrieve(requestParameters: CompeteMatchScrimmagingRecordRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScrimmageRecord> {
        const response = await this.competeMatchScrimmagingRecordRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List matches played in a tournament, or in all tournaments if not specified. Passing the external_id_private of a tournament allows match lookup for the tournament, even if it\'s private. Client uses the external_id_private parameter
     */
    async competeMatchTournamentListRaw(requestParameters: CompeteMatchTournamentListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMatchList>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeMatchTournamentList.');
        }

        const queryParameters: any = {};

        if (requestParameters.externalIdPrivate !== undefined) {
            queryParameters['external_id_private'] = requestParameters.externalIdPrivate;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.roundId !== undefined) {
            queryParameters['round_id'] = requestParameters.roundId;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        if (requestParameters.tournamentId !== undefined) {
            queryParameters['tournament_id'] = requestParameters.tournamentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/match/tournament/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMatchListFromJSON(jsonValue));
    }

    /**
     * List matches played in a tournament, or in all tournaments if not specified. Passing the external_id_private of a tournament allows match lookup for the tournament, even if it\'s private. Client uses the external_id_private parameter
     */
    async competeMatchTournamentList(requestParameters: CompeteMatchTournamentListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMatchList> {
        const response = await this.competeMatchTournamentListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Accept a scrimmage request.
     */
    async competeRequestAcceptCreateRaw(requestParameters: CompeteRequestAcceptCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeRequestAcceptCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeRequestAcceptCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/request/{id}/accept/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept a scrimmage request.
     */
    async competeRequestAcceptCreate(requestParameters: CompeteRequestAcceptCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.competeRequestAcceptCreateRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset for creating and responding to Scrimmage Requests.
     */
    async competeRequestCreateRaw(requestParameters: CompeteRequestCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScrimmageRequest>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeRequestCreate.');
        }

        if (requestParameters.scrimmageRequestRequest === null || requestParameters.scrimmageRequestRequest === undefined) {
            throw new runtime.RequiredError('scrimmageRequestRequest','Required parameter requestParameters.scrimmageRequestRequest was null or undefined when calling competeRequestCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/request/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScrimmageRequestRequestToJSON(requestParameters.scrimmageRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScrimmageRequestFromJSON(jsonValue));
    }

    /**
     * A viewset for creating and responding to Scrimmage Requests.
     */
    async competeRequestCreate(requestParameters: CompeteRequestCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScrimmageRequest> {
        const response = await this.competeRequestCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel a scrimmage request.
     */
    async competeRequestDestroyRaw(requestParameters: CompeteRequestDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeRequestDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeRequestDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/request/{id}/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel a scrimmage request.
     */
    async competeRequestDestroy(requestParameters: CompeteRequestDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.competeRequestDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Get all pending scrimmage requests received.
     */
    async competeRequestInboxListRaw(requestParameters: CompeteRequestInboxListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedScrimmageRequestList>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeRequestInboxList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/request/inbox/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedScrimmageRequestListFromJSON(jsonValue));
    }

    /**
     * Get all pending scrimmage requests received.
     */
    async competeRequestInboxList(requestParameters: CompeteRequestInboxListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedScrimmageRequestList> {
        const response = await this.competeRequestInboxListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all pending scrimmage requests sent.
     */
    async competeRequestOutboxListRaw(requestParameters: CompeteRequestOutboxListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedScrimmageRequestList>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeRequestOutboxList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/request/outbox/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedScrimmageRequestListFromJSON(jsonValue));
    }

    /**
     * Get all pending scrimmage requests sent.
     */
    async competeRequestOutboxList(requestParameters: CompeteRequestOutboxListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedScrimmageRequestList> {
        const response = await this.competeRequestOutboxListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject a scrimmage request.
     */
    async competeRequestRejectCreateRaw(requestParameters: CompeteRequestRejectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeRequestRejectCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeRequestRejectCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/request/{id}/reject/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject a scrimmage request.
     */
    async competeRequestRejectCreate(requestParameters: CompeteRequestRejectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.competeRequestRejectCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new submission. This operation creates a submission record in the database, saves the source code to the storage bucket on Google cloud, and enqueues the submission for compilation on Saturn.
     */
    async competeSubmissionCreateRaw(requestParameters: CompeteSubmissionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Submission>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeSubmissionCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters._package !== undefined) {
            formParams.append('package', requestParameters._package as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.sourceCode !== undefined) {
            formParams.append('source_code', requestParameters.sourceCode as any);
        }

        const response = await this.request({
            path: `/api/compete/{episode_id}/submission/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionFromJSON(jsonValue));
    }

    /**
     * Create a new submission. This operation creates a submission record in the database, saves the source code to the storage bucket on Google cloud, and enqueues the submission for compilation on Saturn.
     */
    async competeSubmissionCreate(requestParameters: CompeteSubmissionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Submission> {
        const response = await this.competeSubmissionCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download the source code associated with a submission.
     */
    async competeSubmissionDownloadRetrieveRaw(requestParameters: CompeteSubmissionDownloadRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubmissionDownload>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeSubmissionDownloadRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeSubmissionDownloadRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/submission/{id}/download/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionDownloadFromJSON(jsonValue));
    }

    /**
     * Download the source code associated with a submission.
     */
    async competeSubmissionDownloadRetrieve(requestParameters: CompeteSubmissionDownloadRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubmissionDownload> {
        const response = await this.competeSubmissionDownloadRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset for creating and retrieving Submissions.
     */
    async competeSubmissionListRaw(requestParameters: CompeteSubmissionListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedSubmissionList>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeSubmissionList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/submission/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSubmissionListFromJSON(jsonValue));
    }

    /**
     * A viewset for creating and retrieving Submissions.
     */
    async competeSubmissionList(requestParameters: CompeteSubmissionListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedSubmissionList> {
        const response = await this.competeSubmissionListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Report the outcome of this submission on Saturn.
     */
    async competeSubmissionReportCreateRaw(requestParameters: CompeteSubmissionReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeSubmissionReportCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeSubmissionReportCreate.');
        }

        if (requestParameters.submissionReportRequest === null || requestParameters.submissionReportRequest === undefined) {
            throw new runtime.RequiredError('submissionReportRequest','Required parameter requestParameters.submissionReportRequest was null or undefined when calling competeSubmissionReportCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/submission/{id}/report/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmissionReportRequestToJSON(requestParameters.submissionReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Report the outcome of this submission on Saturn.
     */
    async competeSubmissionReportCreate(requestParameters: CompeteSubmissionReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.competeSubmissionReportCreateRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset for creating and retrieving Submissions.
     */
    async competeSubmissionRetrieveRaw(requestParameters: CompeteSubmissionRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Submission>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeSubmissionRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competeSubmissionRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/submission/{id}/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionFromJSON(jsonValue));
    }

    /**
     * A viewset for creating and retrieving Submissions.
     */
    async competeSubmissionRetrieve(requestParameters: CompeteSubmissionRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Submission> {
        const response = await this.competeSubmissionRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the submissions used in tournaments by the current team..
     */
    async competeSubmissionTournamentListRaw(requestParameters: CompeteSubmissionTournamentListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentSubmission>>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling competeSubmissionTournamentList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/compete/{episode_id}/submission/tournament/`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentSubmissionFromJSON));
    }

    /**
     * Retrieve the submissions used in tournaments by the current team..
     */
    async competeSubmissionTournamentList(requestParameters: CompeteSubmissionTournamentListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentSubmission>> {
        const response = await this.competeSubmissionTournamentListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
